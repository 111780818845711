@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.productcontainer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.product-details {
  background: #ffffff;
  position: relative;
  margin-top: 16px;
}

.details-content {
  display: flex;
  flex-direction: column;
}

.product-info {
  width: 100%;
}

.product-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.product-title {
  font-size: 1.6rem;
  color: #4e555e;
}

.product-sku {
  font-size: 1.2rem;
  color: #4e555e;
}

.price-breakup {
  margin-bottom: 12px;
}

.price-breakup-button {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.2rem;
}

.price-breakup-button .icon {
    background: url(https://assets.cltstatic.com/images/responsive/pdp-sprites/pdp-delivery-tah-sprite.png?v2.0) -113px -41px / 280px no-repeat;
  width: 18px;
  height: 14px;
  display: inline-block;
  margin-right: 7px;
}

.button-text {
  font-size: 1.2rem;
  color: #4f3267;
}

.product-description {
  margin-bottom: 12px;
  background: #fff3e9;
  border-radius: 8px;
  padding: 10px;
}

.details-boxes {
  display: flex;
  flex-direction: column;
}

.details-box {
  background: #fff9f5;
  margin-bottom: 12px;
  border-radius: 8px;
  padding: 10px;
}

.box-header {
  display: flex;
  align-items: center;
  padding: 12.5px 16px;
  background: #fff3e9;
  border-radius: 8px 8px 0 0;
}

.box-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #4f3267;
  text-transform: uppercase;
}

.box-item {
  margin-bottom: 10px;
}

.item-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #4f3267;
}

.box-item p {
  font-size: 1.2rem;
  color: #4e555e;
}

.gold-icon {
  background: url('https://assets.cltstatic.com/images/responsive/pdp-sprites/pdp-delivery-tah-sprite.png?v2.0') -113px -41px / 280px no-repeat;
  width: 18px;
  height: 14px;
  display: inline-block;
  margin-right: 7px;
}

.diamond-icon {
  background: url('https://assets.cltstatic.com/images/responsive/pdp-sprites/pdp-delivery-tah-sprite.png?v2.0') -115px -68px / 280px no-repeat;
  width: 18px;
  height: 14px;
  display: inline-block;
  margin-right: 7px;
  margin-top: 3px;
}


.trust-section {
  position: relative;
}

.mount-listener {
  position: absolute;
  top: -400px;
  width: 100%;
}

.trust-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 0 0 100%;
  max-width: 1440px;
  margin: 0 auto;
  background: #ffffff;
}

.item {
  padding: 8px;
  margin-right: 0;
}

.icon {
  margin: 4px;
  text-align: center;
}

.icon-bis {
  background-image: url('https://www.igi.org/wp-content/uploads/hero-logo.png');
  background-size: contain; /* Ensure the image scales properly */
  background-position: center; /* Position the image in the center */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  width: 45px;
  height: 40px;
  display: inline-block;
}

.icon-trust {
  background: url('https://assets.cltstatic.com/images/responsive/pdp-sprites/pdp-delivery-tah-sprite.png?v2.0') -200px -87px / 280px no-repeat;
  width: 73px;
  height: 30px;
  display: inline-block;
}

.icon-certified {
  background: url('https://assets.cltstatic.com/images/responsive/pdp-sprites/pdp-delivery-tah-sprite.png?v2.0') -158px -84px / 280px no-repeat;
  width: 36px;
  height: 36px;
  display: inline-block;
}

.text {
  text-align: center;
  color: rgb(79, 50, 103);
}

.main-text {
  margin-right: 4px;
  
  font-size: 1.2rem;
  white-space: nowrap;
}

.sub-text {
  
  font-size: 1rem;
  white-space: nowrap;
}


.shop-details {
  position: relative;
}

.product-top-info {
  background: #ffffff;
}

.section-padding {
  padding: 15px;
}

.section-container {
  padding-left: 15px;
  padding-right: 15px;
}

.content-thumbnail-scroll {
  overflow: hidden;
}

.image-thumbnail .slick-slide img {
  width: 100%;
}

.image-additional .slick-slide img {
  width: 100%;
}

.scroll-image {
  position: relative;
}


/* Highlight the current page number */
.page-numbers.current {
  background-color: #c0a483; /* Change to your desired background color */
  color: white; /* Change to your desired text color */
  border-radius: 5px; /* Optional: adds rounded corners */
  padding: 5px 10px; /* Optional: adds some padding */
  text-decoration: none; /* Remove underline */
}

/* Optional: Add hover effect for other page numbers */
.page-numbers:not(.current):hover {
  background-color: #f0f0f0; /* Change to your desired hover color */
  color: #c0a483; /* Change to your desired hover text color */
}

.custom-arrow {
  position: absolute;
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  z-index: 10;
  font-size: 24px;
  color: rgb(253, 157, 99); /* Set arrow color */
  cursor: pointer;
  display: block;
  
}

@media (min-width: 768px) {
  .custom-arrow {
    display: none; /* Hide arrows on screens wider than 768px */
  }
}


.custom-prev-arrow {
  left: 5px; /* Adjust as needed */
}

.custom-next-arrow {
  right: 5px; /* Adjust as needed */
}

/* Additional styling for better visibility */
.custom-arrow:hover {
  color: #cb8161; /* Change color on hover */
}


@media (max-width: 767px) {
  .mobilehide-products {
    display: none; /* Hide on mobile (screens smaller than 768px) */
  }
}

/* Show the product block on desktop */
@media (min-width: 768px) {
  .mobilehide-products {
    display: block; /* Show on desktop (screens larger than or equal to 768px) */
  }
}